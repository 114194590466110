<template>
  <vx-card :title="title">
    <div class="vx-row mb-6 w-full mt-8">
      <div class="vx-col mb-6 w-1/2 mt-8">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Warehouse</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedWarehouse"
              :options="optionWarehouse"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="100"
              :limit="3"
              placeholder="Type to search"
              :searchable="true"
              :custom-label="customLableWarehouse"
            />
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedTerritory"
              :options="optionTerritory"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="100"
              :limit="3"
              placeholder="Type to search"
              :searchable="true"
              :custom-label="customLableTerritory"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Sales Return Date</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <date-range-picker
              style="min-height: 40px"
              class="w-full"
              ref="picker"
              opens="center"
              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
              :singleDatePicker="false"
              :timePicker="false"
              :timePicker24Hour="false"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="true"
              v-model="filterDate"
              :linkedCalendars="true"
            >
              <template v-slot:input="picker" style="min-width: 350px">
                {{ datePickerDateFormat(picker.startDate) }} -
                {{ datePickerDateFormat(picker.endDate) }}
              </template>
            </date-range-picker>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/4 w-full">
            <span></span>
          </div>
          <div class="vx-row sm:w-1/3 w-full space-x-1">
            <vs-button
              @click="onClickBtnFilter"
              size="small"
              color="primary"
              icon-pack="feather"
              icon="icon-search"
              title="Search"
            >
              Filter</vs-button
            >
            <vs-button
              @click="onClickBtnClear"
              size="small"
              color="primary"
              icon-pack="feather"
              icon="icon-trash-2"
              title="Search"
            >
              Clear</vs-button
            >
          </div>
        </div>
      </div>
      <div class="vx-col mb-6 w-1/2 mt-8">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Salesman</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedSalesman"
              :options="optionSalesman"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="100"
              :limit="3"
              placeholder="Type to search"
              :searchable="true"
              :custom-label="customLableSalesman"
              @search-change="handlesearchSalesman"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Customer</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomer"
              :options="optionCustomer"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="100"
              :limit="3"
              placeholder="Type to search"
              :searchable="true"
              :custom-label="customLableCustomer"
              @search-change="handleSearchCustomer"
            />
          </div>
        </div>
      </div>
    </div>
    <vs-tabs :value="active">
      <vs-tab label="New">
        <New
          :selectedWarehouse="selectedWarehouse.id"
          :selectedTerritory="selectedTerritory.id"
          :selectedSalesman="selectedSalesman ? selectedSalesman.id : null"
          :selectedCustomer="selectedCustomer ? selectedCustomer.id : null"
          :dateStart="filterDate.startDate"
          :dateEnd="filterDate.endDate"
          :filterActive="this.filterActive"
          @checkFilter="handleCheckFilter"
          @selectedItems="handleSelectItem"
        ></New>
      </vs-tab>
      <vs-tab label="Assigned">
        <div class="tab-text">
          <Assigned
            :selectedWarehouse="selectedWarehouse.id"
            :selectedTerritory="selectedTerritory.id"
            :selectedSalesman="selectedSalesman ? selectedSalesman.id : null"
            :selectedCustomer="selectedCustomer ? selectedCustomer.id : null"
            :dateStart="filterDate.startDate"
            :dateEnd="filterDate.endDate"
            :filterActive="this.filterActive"
            @checkFilter="handleCheckFilter"
          ></Assigned>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import New from "./_tab/table-new.vue";
import Assigned from "./_tab/table-assigned.vue";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  props: {
    active: {
      type: Number,
    },
  },
  components: {
    New,
    Assigned,
    DateRangePicker,
  },
  mounted() {
    this.getWarehouse();
    this.getTerritory();
  },
  data() {
    return {
      table: this.tableDefaultState(),
      title: "Pickup Request",
      territoryID: null,
      warehouseID: null,
      optionTerritory: [],
      selectedTerritory: { name: "", code: "", id: 0 },
      optionWarehouse: [],
      selectedWarehouse: { name: "", code: "", id: 0, territory_area_id: 0 },
      optionSalesman: [],
      selectedSalesman: null,
      optionCustomer: [],
      selectedCustomer: null,
      filterDate: {
        startDate: "",
        endDate: "",
      },
      filterActive: false,
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    onClickBtnFilter() {
      this.territoryID = this.selectedTerritory.id;

      this.warehouseID = this.selectedWarehouse.id;

      this.filterActive = true;
    },
    handleCheckFilter() {
      console.log(">>>>>ini check filter v2", this.filterActive);
      if (this.filterActive) {
        this.filterActive = false;
      }
    },
    handleBTNWarehouse() {
      console.log(">>>>qqq", this.selectedWarehouse);

      let params = {
        ...this.table,
      };

      params.territory_area = this.selectedWarehouse.territory_area_id;

      this.getTerritory(params);
    },
    handlesearchSalesman(searching) {
      console.log(">>>>qqq", this.selectedSalesman);
      if (searching.length > 3) {
        this.table.search = searching;

        if (this.selectedTerritory.id != 0 && this.selectedWarehouse.id != 0) {
          let params = {
            search: searching,
            territory_id: this.selectedTerritory.id,
            warehouse_id: this.selectedWarehouse.id,
          };

          this.getSalesman(params);
        }
      }
    },
    handleSearchCustomer(searching) {
      console.log(">>>>qqq", this.selectedCustomer);
      if (searching.length > 3) {
        this.table.search = searching;

        if (this.selectedTerritory.id != 0) {
          let params = {
            search: searching,
            territory_id: this.selectedTerritory.id,
          };

          this.getCustomer(params);
        }
      }
    },
    onClickBtnClear() {
      this.selectedWarehouse = {
        name: this.$userLogin.warehouse_name
          ? this.$userLogin.warehouse_name
          : this.optionWarehouse[0].name,
        code: this.$userLogin.warehouse_code
          ? this.$userLogin.warehouse_code
          : this.optionWarehouse[0].code,
        id: this.$userLogin.warehouse_id
          ? this.$userLogin.warehouse_id
          : this.optionWarehouse[0].id,
      };
      this.selectedTerritory = {
        name: this.$userLogin.territory_name
          ? this.$userLogin.territory_name
          : this.optionTerritory[0].name,
        code: this.$userLogin.territory_code
          ? this.$userLogin.territory_code
          : this.optionTerritory[0].code,
        id: this.$userLogin.territory_id
          ? this.$userLogin.territory_id
          : this.optionTerritory[0].id,
      };
      this.selectedSalesman = null;
      this.selectedCustomer = null;
      this.filterDate = {
        startDate: "",
        endDate: "",
      };
      this.filterActive = true;
    },
    getWarehouse() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/warehouses", {
          params: {
            length: 1000,
            order: "name",
            sort: "asc",
            by_personal: 1,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionWarehouse = resp.data.records;
              if (this.optionWarehouse.length > 0) {
                this.selectedWarehouse = {
                  name: this.$userLogin.warehouse_name
                    ? this.$userLogin.warehouse_name
                    : this.optionWarehouse[0].name,
                  code: this.$userLogin.warehouse_code
                    ? this.$userLogin.warehouse_code
                    : this.optionWarehouse[0].code,
                  id: this.$userLogin.warehouse_id
                    ? this.$userLogin.warehouse_id
                    : this.optionWarehouse[0].id,
                  territory_area_id: this.optionWarehouse[0].territory_area_id,
                };
                // this.handleBTNWarehouse();
              }
            } else {
              this.optionWarehouse = [];
              this.selectedWarehouse = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getTerritory() {
      // console.log("ooo", params);
      // this.optionTerritory = [];
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/territories", {
          params: {
            length: 9999,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            by_personal: 1,
            // territory_area: params.territory_area,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length > 0) {
                this.selectedTerritory = {
                  name: this.$userLogin.territory_name
                    ? this.$userLogin.territory_name
                    : this.optionTerritory[0].name,
                  code: this.$userLogin.territory_code
                    ? this.$userLogin.territory_code
                    : this.optionTerritory[0].code,
                  id: this.$userLogin.territory_id
                    ? this.$userLogin.territory_id
                    : this.optionTerritory[0].id,
                };
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getSalesman(params) {
      console.log("ooo", params);
      this.optionSalesman = [];
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/transport-pickup/sales-personal", {
          params: {
            // length: 9999,
            // page: this.table.page,
            search: params.search,
            // order: this.table.order,
            // sort: this.table.sort,
            territory_id: params.territory_id,
            warehouse_id: params.warehouse_id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionSalesman = resp.data.records;
              // if (this.optionSalesman.length > 0) {
              //   this.selectedSalesman = {
              //     name: this.optionSalesman[0].name,
              //     id: this.optionSalesman[0].id,
              //   };
              // }
            } else {
              this.optionSalesman = [];
              this.selectedSalesman = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCustomer(params) {
      this.optionCustomer = [];
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/transport-pickup/customer", {
          params: {
            // length: 9999,
            // page: this.table.page,
            search: params.search,
            // order: this.table.order,
            // sort: this.table.sort,
            territory_id: params.territory_id,
            // warehouse_id: params.warehouse_id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionCustomer = resp.data.records;
              // if (this.optionCustomer.length > 0) {
              //   this.selectedCustomer = {
              //     name: this.optionCustomer[0].name,
              //     id: this.optionCustomer[0].id,
              //   };
              // }
            } else {
              this.optionCustomer = [];
              this.selectedCustomer = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },

    customLableWarehouse({ code, name }) {
      return `${code} ${name}`;
    },
    customLableTerritory({ code, name }) {
      return `${code} ${name}`;
    },
    customLableSalesman({ name, work_id_number }) {
      return `${name}-${work_id_number}`;
    },
    customLableCustomer({ name }) {
      return `${name}`;
    },
    datePickerDateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("dddd, MMMM Do YYYY");
      }
      return a;
    },
  },
  watch: {
    selectedWarehouse: {
      handler: function () {
        let params = {
          territory_id: this.selectedTerritory.id,
          warehouse_id: this.selectedWarehouse.id,
        };
        this.getSalesman(params);
      },
    },
    selectedTerritory: {
      handler: function () {
        let params = {
          territory_id: this.selectedTerritory.id,
          warehouse_id: this.selectedWarehouse.id,
        };
        this.getSalesman(params);
        this.getCustomer(params);
      },
    },
  },
};
</script>
